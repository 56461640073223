import { ElNotification, ElLoading } from 'element-plus';
import { Md5 } from "ts-md5";

export let loadingCount = 0;

// cacheResp  0：禁用、1：开启、2：自动设置
export const requestApi = (path: string, callback: any = undefined, query: any = [], args: object = {}, method: any = 'get', callbackFail: any = undefined, showLoading = true, cacheResp = 2) => {
  // 缓存请求
  let requestMd5Key = Md5.hashStr(path + JSON.stringify(query) + JSON.stringify(args) + method)

  if (cacheResp == 1) {
    let cacheRespBody = localStorage.getItem(`resp_${requestMd5Key}`);
    if (cacheRespBody != null) {
      callback(JSON.parse(cacheRespBody))
    }
  }

  let loading = {
    close() {
      return
    }
  }
  if (showLoading) {
    //console.log(`show loading: ${path}; cache resp: ${cacheResp}`)
    loadingCount += 1;
    loading = ElLoading.service({
      lock: true,
      text: '加载中',
      background: 'rgba(255, 255, 255, 0.3)',
    })
  }
  let queryArr: any = []
  Object.keys(query).forEach(function (key) {
    queryArr.push(key + "=" + query[key])
  })
  const queryString = queryArr.join('&')
  let reqPath = process.env.VUE_APP_BASE_API + path
  if (queryString != '') {
    reqPath += '?' + queryString
  }
  const authHeader = localStorage.getItem('jwt_token') == null ? '' : 'Bearer ' + localStorage.getItem('jwt_token')

  let agentId = "main";
  if (window.location.host.indexOf(process.env.VUE_APP_NO_AGENT_DOMAIN) == -1) {
    agentId = Md5.hashStr(window.location.host);
  }

  let requestInit: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-Site-Agent-Id': agentId,
      'Authorization': authHeader
    }
  }
  if (method != 'get' && method != 'head') {
    requestInit.body = JSON.stringify(args)
  }
  fetch(reqPath, requestInit)
    .then(res => res.json())
    .then(res => {
      localStorage.setItem(`resp_${requestMd5Key}`, JSON.stringify(res));
      setTimeout(() => {
        if (showLoading) {
          loadingCount -= 1
          if (!loadingCount) {
            loading.close()
          }
        }
      }, 30);
      if (callback) {
        callback(res)
      } else {
        ElNotification({
          title: res.code == 1 ? '请求成功' : '登录失败',
          message: res.msg,
          type: res.code == 1 ? 'success' : 'error'
        })
      }
    }).catch(error => {
      if (showLoading) {
        loadingCount -= 1
        if (!loadingCount) {
          loading.close()
        }
      }
      ElNotification({
        title: '出错了',
        message: '请求接口时遇到了一个致命的错误，请联系管理员！[' + error.message + ']',
        type: 'error'
      })
      if (callbackFail) {
        callback(error)
      }
    })

}

export const forbidBodyScoll = () => {
  const body = window.document.getElementsByTagName('body')[0];
  body.style.overflow = 'hidden';
};

export const objArraySort = (objArr: any, key: string) => {
  let result = objArr.slice(0);
  return result.sort((a: any, b: any) => a[key] - b[key]);
}

export const showNotification = (res: any) => {
  let errMsgExtra = '';
  if (res.data.err != undefined) {
    if (res.data.err.length != 0) {
      if (typeof (res.data.err) == 'string') {
        errMsgExtra = ` [${res.data.err}]`
      } else {
        errMsgExtra = ` [${res.data.err.join(',')}]`
      }
    }
  }

  ElNotification({
    title: res.code == 1 ? '操作成功' : '操作失败',
    message: res.msg + errMsgExtra,
    type: res.code == 1 ? 'success' : 'error'
  })
};

export const validateIP = (str: string) => {
  const re =
    /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
  return re.test(str)
}

export const formatDate = (value: number) => {
  let date = new Date(value);//这个是纳秒的，想要毫秒的可以不用除以1000000
  let y: any = date.getFullYear();
  let MM: any = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d: any = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h: any = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let m: any = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s: any = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export const getCdnPrice = (callback: any) => {
  let cdnPrice = 11;
  requestApi('/user/info', function (rdata: any) {
    if (rdata.code == 1) {
      if (rdata.data.config.price == undefined) {
        requestApi('/common/cdn/config', function (rdata: any) {
          if (rdata.code == 1) {
            cdnPrice = rdata.data.price
            callback(cdnPrice)
          }
        })
      } else {
        cdnPrice = rdata.data.config.price
        callback(cdnPrice)
      }
    }
  })
}

export const getSiteInfo = (callback: any) => {
  let reqPath = ""
  if (window.location.host.indexOf(process.env.VUE_APP_NO_AGENT_DOMAIN) == -1) {
    let agentId = Md5.hashStr(window.location.host);
    reqPath = `/common/agent/${agentId}/config`
  } else {
    reqPath = `/common/config`
  }
  requestApi(reqPath, (rdata: any) => {
    if (rdata.code == 1) {
      callback(rdata.data)
    }
  }, {}, {}, 'get', false, false, 1)
}