<template>
  <div style="min-width: 80px">
    <h3 style="padding-left: 40px; margin-bottom: 30px; color: white">
      {{ siteName }}
    </h3>
    <el-menu
      text-color="#ffffff"
      active-text-color="#fffffff"
      background-color="#303133"
      class="custom-menu-style"
      :default-active="defaultActive"
      style="--el-menu-border-color: #303133"
    >
      <router-link
        style="text-decoration: none; color: white"
        v-for="menu in menuList"
        :key="menu"
        :to="{ name: menu.name }"
      >
        <el-menu-item
          class="custom-menu-style"
          :style="`${menu.hide ? 'display:none' : ''}`"
          :index="menu.index"
        >
          <el-icon style="font-size: 16px">
            <component :is="menu.icon"></component>
          </el-icon>
          <template #title>
            <h3>
              {{ menu.title }}
            </h3>
          </template>
        </el-menu-item>
      </router-link>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { requestApi, getSiteInfo } from "../../plugins/common";
import { useRouter } from "vue-router";
const router = useRouter();
const routerList = router.getRoutes();
const siteName = ref("");
let enableAgentLiteAct = ref(false);

let defaultActive = ref("-1");
let menuList = ref(menuEach([], routerList));

if (window.location.host.indexOf(process.env.VUE_APP_NO_AGENT_DOMAIN) == -1) {
  requestApi(`/user/agent/status`, function (res: any) {
    if (res.code == 1) {
      enableAgentLiteAct.value = true;
      menuList.value = menuEach([], routerList);
    }
  });
}

getSiteInfo((res) => {
  siteName.value = res.site_name;
});

function menuEach(menuData: any, menuList: any[]) {
  let index = 0;
  menuList.forEach((menuInfo) => {
    if (menuInfo.meta.name != undefined) {
      if (menuInfo.name == router.currentRoute.value.name) {
        defaultActive.value = index.toString();
      }
      let menuChip = {
        index: index.toString(),
        title: menuInfo.meta.title,
        name: menuInfo.name,
        hide: menuInfo.meta.hide ? true : false,
        icon: menuInfo.meta.icon,
      };
      if (enableAgentLiteAct.value) {
        if (menuChip.name == "AgentLite") {
          menuChip.hide = false;
        }
      }
      menuData.push(menuChip);
      index++;
    }
  });

  return menuData;
}
</script>
<style>
.custom-menu-style {
  --el-menu-item-height: 40px;
  --el-menu-item-font-size: 12px;
}
</style>
