import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import { ElNotification } from 'element-plus'
import { shallowRef } from 'vue'
import { getSiteInfo } from '@/plugins/common'
import { CreditCard, User, Link, Filter, Lock, Refresh, Money, Place, Message, Tickets, Star, Lollipop, Notebook } from '@element-plus/icons-vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LoginPage',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'RedirectDashboard',
        redirect: '/dashboard/overview'
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        children: [
          {
            path: 'overview',
            name: 'OverView',
            component: () => import('../views/OverviewView.vue'),
            meta: {
              name: '概览',
              title: '概览',
              icon: shallowRef(CreditCard)
            },
          }
        ]
      },
      {
        path: "cdn",
        name: "CdnCenter",
        children: [
          {
            path: 'list',
            name: 'CdnList',
            component: () => import('../views/CdnView.vue'),
            meta: {
              title: '域名管理',
              icon: shallowRef(Link),
              name: '域名管理'
            }
          },
          {
            path: 'manage',
            name: 'CdnManage',
            component: () => import('../views/CdnManageView.vue'),
            meta: {
              title: '域名配置'
            }
          },
          {
            path: 'refresh',
            name: 'CacheRefresh',
            component: () => import('../views/CacheRefreshView.vue'),
            meta: {
              name: '缓存刷新',
              icon: shallowRef(Refresh),
              title: '缓存刷新'
            }
          },
          {
            path: 'statistics',
            name: 'CdnStatistics',
            component: () => import('../views/CdnStatisticsView.vue'),
            meta: {
              name: '统计分析',
              icon: shallowRef(Filter),
              title: '统计分析'
            }
          },
          {
            path: 'ip-info',
            name: 'QueryCdnIpInfo',
            component: () => import('../views/CdnIpInfoView.vue'),
            meta: {
              name: '节点归属',
              icon: shallowRef(Place),
              title: '节点归属'
            }
          },
          {
            path: "cert",
            name: "CertCenter",
            component: () => import('../views/CertListView.vue'),
            meta: {
              title: '证书中心',
              icon: shallowRef(Lock),
              name: '证书中心'
            }
          },
          {
            path: "cert/acme",
            name: "AcmeCert",
            component: () => import('../views/AcmeCertView.vue'),
            meta: {
              title: '证书申请',
              icon: shallowRef(Lock),
            }
          },
          {
            path: 'package',
            name: 'PackageListView',
            component: () => import('../views/PackageView.vue'),
            meta: {
              name: '流量包管理',
              icon: shallowRef(Star),
              title: '流量包管理'
            }
          },
        ]
      },
      {
        path: "user",
        name: "User",
        children: [
          {
            path: 'center',
            name: 'UserCenter',
            component: () => import('../views/UserView.vue'),
            meta: {
              title: '用户中心',
              icon: shallowRef(User)
            }
          },
          {
            path: 'realname',
            name: 'RealnamePage',
            component: () => import('../views/RealnameView.vue'),
            meta: {
              title: '用户中心',
              icon: shallowRef(User)
            }
          },
          {
            path: 'agent',
            name: 'AgentLite',
            component: () => import('../views/AgentLiteView.vue'),
            meta: {
              title: '推广返现',
              name: '推广返现',
              hide: true,
              icon: shallowRef(Lollipop)
            }
          },
          {
            path: 'financial-mgt',
            name: 'FinancialManagement',
            component: () => import('../views/FinancialMgt.vue'),
            meta: {
              title: '财务管理',
              name: '财务管理',
              icon: shallowRef(Money)
            }
          },
          {
            path: 'log',
            name: 'UserLogPage',
            component: () => import('../views/LogView.vue'),
            meta: {
              title: '日志管理',
              name: '日志管理',
              icon: shallowRef(Notebook)
            }
          }
        ]
      },
      {
        path: "notify",
        name: "NotifyMain",
        children: [
          {
            path: 'rule-list',
            name: 'RuleListPage',
            component: () => import('../views/NotifyRuleListView.vue'),
            meta: {
              title: '通知规则',
              name: '通知规则',
              icon: shallowRef(Message)
            }
          },
        ]
      },
      {
        path: "ticket",
        name: "TicketMain",
        children: [
          {
            path: 'list',
            name: 'TicketListPage',
            component: () => import('../views/TicketView.vue'),
            meta: {
              title: '工单管理',
              name: '工单管理',
              icon: shallowRef(Tickets)
            }
          },
          {
            path: 'detail',
            name: 'TicketMgtPage',
            component: () => import('../views/ManageTicket.vue'),
            meta: {
              title: '工单处理',
              icon: shallowRef(Tickets)
            }
          },
        ]
      },
      {
        path: "support",
        name: "Support",
        children: [
          {
            path: 'contact',
            name: 'ContactMain',
            component: () => import('../views/ContactView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录平台'
    }
  },
  {
    path: '/wechat-bind',
    name: 'WechatBind',
    component: () => import('../views/WeChatBindView.vue'),
    meta: {
      title: '微信绑定'
    }
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('../views/RegView.vue'),
    meta: {
      title: '注册账号'
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/ForgetView.vue'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/404',
    name: 'NotFount',
    component: () => import('../views/NotFountView.vue'),
    meta: {
      title: '找不到指定页面'
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (document.documentElement.clientWidth < 800 && localStorage.getItem("notify_id") == 'A') {
    ElNotification({
      title: '推荐使用PC端访问本页',
      message: '当前页面未进行移动设备适配，在移动设备中访问本页可能遇到包括不限于页面样式错乱、页面功能异常等问题，推荐使用PC端进行访问！对您造成的不便我们深感抱歉！',
      type: 'warning'
    })
    localStorage.setItem("recommond_pc", 'A');
  }
  const allowURL = [
    '/login',
    '/reg',
    '/wechat-bind',
    '/404',
    '/forget'
  ]
  const jwtToken = localStorage.getItem('jwt_token')
  if (allowURL.indexOf(to.path) == -1 && typeof (jwtToken) == 'object') {
    ElNotification({
      title: '未登录',
      message: '请先登录后再次访问本页！',
      type: 'warning'
    })
    return next('/login')
  }
  getSiteInfo((res: any) => {
    let siteName = res.site_name
    if (to.meta.title && typeof (to.meta?.title) === 'string') {
      document.title = to.meta.title + ' - ' + siteName
    } else {
      document.title = siteName
    }
  })

  next()
})
export default router
