import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { getSiteInfo } from "@/plugins/common"
import "@/style/common.css"

const apiPath = process.env.VUE_APP_BASE_API

const app = createApp(App)
app.use(ElementPlus)
  .use(router)
  .mount('#app')

getSiteInfo((res: any) => {
  localStorage.setItem('site_name', res.site_name);
  localStorage.setItem('site_icp', res.icp);
  localStorage.setItem('site_logo', res.logo);
})

app.config.globalProperties.$apiPath = apiPath