<template>
  <el-container style="background: #303133">
    <el-aside
      v-if="clientWidth > 800"
      style="margin-top: 10px; width: 200px; background: #303133"
    >
      <MainDrawer />
    </el-aside>
    <div v-else>
      <el-drawer
        :with-header="false"
        direction="ltr"
        style="--el-drawer-padding-primary: 0px; background: #303133"
        size="200"
        v-model="showDrawer"
      >
        <span>
          <MainDrawer />
        </span>
      </el-drawer>
    </div>
    <el-container>
      <el-header style="background-color: #303133; color: #000000; height: 65px">
        <el-row :gutter="24">
          <el-col :span="3">
            <el-icon
              v-if="clientWidth <= 800"
              class="el-icon-style"
              style="color: white"
              size="20"
              @click="showDrawer = !showDrawer"
            >
              <Fold />
            </el-icon>
          </el-col>
          <el-col :span="21" style="text-align: right; margin-top: 2px">
            <el-link
              :underline="false"
              style="
                display: inline-block;
                height: 50%;
                padding: 20px 20px 10px 5px;
                color: white;
              "
              @click="showUserDrawer = !showUserDrawer"
            >
              <a
                style="color: white; margin-right: 12px"
                v-if="documentUrl != ''"
                :href="documentUrl"
              >
                <el-icon style="vertical-align: -25%" size="20"> <Document /> </el-icon>
              </a>

              <el-icon
                v-if="totalNotify == 0"
                @click="showNotifyDrawer = !showNotifyDrawer"
                style="vertical-align: -25%; margin-right: 24px"
                size="20"
              >
                <Bell />
              </el-icon>
              <el-badge
                @click="showNotifyDrawer = !showNotifyDrawer"
                v-else
                :value="totalNotify"
                style="margin-right: 24px"
              >
                <el-icon style="vertical-align: -25%" size="20"> <Bell /> </el-icon>
              </el-badge>
              <el-dropdown>
                <div style="color: white">
                  欢迎，{{ userName }}&nbsp;&nbsp;
                  <el-icon style="vertical-align: -25%" size="20">
                    <UserFilled />
                  </el-icon>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link
                      style="text-decoration: none"
                      :to="{ name: 'UserCenter' }"
                    >
                      <el-dropdown-item>用户中心</el-dropdown-item>
                    </router-link>
                    <router-link
                      style="text-decoration: none"
                      :to="{ name: 'FinancialManagement' }"
                    >
                      <el-dropdown-item>财务管理</el-dropdown-item>
                    </router-link>
                    <router-link
                      style="text-decoration: none"
                      :to="{ name: 'TicketListPage' }"
                    >
                      <el-dropdown-item>工单管理</el-dropdown-item>
                    </router-link>
                    <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-link>
          </el-col>
        </el-row>
      </el-header>
      <el-main style="--el-main-padding: 0px; background-color: #f2f3f5">
        <el-scrollbar height="100%">
          <div style="padding: 20px; max-width: 1400px; margin: auto">
            <PageHeader />
            <el-card
              v-if="recommendRealname"
              shadow="hover"
              style="background-color: #ffcccc"
            >
              <div style="font-size: 10px; color: #ff3333; line-height: 20px">
                · 根据相关法规和政策要求，必须完成账号实名认证才能使用相关功能。<br />
                · 请尽快完成实名认证，否则可能会影响部分功能的使用。<a
                  href="/user/realname"
                  style="color: black"
                  >点击此处跳转到实名认证页</a
                >
              </div>
            </el-card>
            <router-view />
            <div style="margin: 20px; font-size: 14px; color: grey; text-align: center">
              <a target="_blank" style="color: grey" href="https://beian.miit.gov.cn/">{{
                siteIcpCode
              }}</a>
            </div>
          </div>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
  <el-dialog width="60%" v-model="showGlobalNotifyDialog" title="全站公告">
    {{ globalNotifyData }}
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showGlobalNotifyDialog = false">关闭</el-button>
        <el-button type="primary" @click="confirmReadNotify">确认已读</el-button>
      </span>
    </template>
  </el-dialog>
  <el-drawer
    :with-header="false"
    v-model="showNotifyDrawer"
    title="通知列表"
    direction="rtl"
    style="max-width: 400px; min-width: 400px"
  >
    <div style="display: flex">
      <div style="flex: 50%; font-size: 20px; font-weight: 400">通知列表</div>
      <div style="flex: 50%; text-align: right">
        <el-icon @click="showNotifyDrawer = !showNotifyDrawer"><Close /></el-icon>
      </div>
    </div>
    <div v-if="showNotifyDrawer">
      <NotifyList />
    </div>
  </el-drawer>
</template>

<script setup>
import "@/style/main-layout.css";
import { ref, watch } from "vue";
import { Close, Fold, UserFilled, Bell, Document } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import { requestApi, forbidBodyScoll } from "../plugins/common";
import { jwtDecode } from "jwt-decode";
import { Md5 } from "ts-md5";
import MainDrawer from "@/components/layout/MainDrawer.vue";
import NotifyList from "@/components/layout/NotifyList.vue";
import PageHeader from "@/components/layout/PageHeader.vue";

forbidBodyScoll();
const router = useRouter();
const userName = ref("尊敬的客户");
let showNotifyDrawer = ref(false);
let showDrawer = ref(false);
let showGlobalNotifyDialog = ref(false);
let clientWidth = ref(document.documentElement.clientWidth);
let globalNotifyData = ref("这是一条公告");
let showNoBalance = ref(true);
let showUserDrawer = ref(false);
let userBalance = ref(0);
let userPhone = ref("12345678901");
let recommendRealname = ref(false);
let documentUrl = ref(process.env.VUE_APP_DOCUMENT_URL);
let siteIcpCode = localStorage.getItem("site_icp");
let userInfo = jwtDecode(localStorage.getItem("jwt_token"));
let totalNotify = ref(0);
let userId = 0;

watch(showNotifyDrawer, () => {
  getNotifyCount();
});

requestApi(
  userInfo.bind_id == ""
    ? "/user/notify/global"
    : `/common/agent/${userInfo.bind_id}/full_config`,
  function (res) {
    if (res.code == 1) {
      if (res.data.notify == null) {
        return false;
      }
      if (localStorage.getItem("notify_id") == Md5.hashStr(res.data.notify.content)) {
        return false;
      }
      globalNotifyData.value = res.data.notify.content;
      showGlobalNotifyDialog.value = true;
    }
  },
  {},
  {},
  "get",
  false,
  true,
  true
);

function confirmReadNotify() {
  localStorage.setItem("notify_id", Md5.hashStr(globalNotifyData.value));
  showGlobalNotifyDialog.value = false;
}

const logoutAuto = () => {
  localStorage.removeItem("jwt_token");
  router.push({ path: "/login" });
  ElNotification({
    title: "登录过期",
    message: "请重新登录以继续您的操作！",
    type: "error",
  });
};

const getNotifyCount = () => {
  requestApi(
    "/user/notify/list",
    (res) => {
      if (res.code == 1) {
        totalNotify.value = res.data.unread_total;
      } else {
        logoutAuto();
      }
    },
    {},
    {},
    "get",
    false,
    false,
    true
  );
};
getNotifyCount();

getUserInfo();
function getUserInfo() {
  requestApi(
    "/user/info",
    function (res) {
      if (res.code == 1) {
        if (userId != 0 && userId != res.data.user_id) {
          //console.log("clean local cache");
          let jwtToken = localStorage.getItem("jwt_token");
          localStorage.clear();
          localStorage.setItem("jwt_token", jwtToken);
          location.reload();
        }
        userId = res.data.user_id;
        if (res.data.status != 1) {
          recommendRealname.value = true;
        }
        userBalance.value = res.data.balance.toString();
        userPhone.value = res.data.phone;
        if (res.data.balance < 10 && showNoBalance.value) {
          ElNotification({
            title: "余额即将不足",
            message:
              "您剩余余额" +
              towNumber(res.data.balance / 100, false).toString() +
              "元，已不足10元，可能对您的CDN业务产生影响，请尽快充值！",
            type: "warning",
            duration: 0,
          });
          showNoBalance.value = false;
        }
        localStorage.setItem("user_info", res.data);
        userName.value = res.data.username;
      } else {
        logoutAuto();
      }
    },
    {},
    {},
    "get",
    false,
    false,
    true
  );
}

let mainLayoutTimeer = setInterval(() => {
  getNotifyCount();
}, 10000);
localStorage.setItem("main_layout_timer", mainLayoutTimeer.toString());

function logout() {
  localStorage.removeItem("jwt_token");
  router.push({ path: "/login" });
}

window.onresize = function () {
  clientWidth.value = document.documentElement.clientWidth;
};

function towNumber(val, func = true) {
  if (val.toFixed(2) == "0.00" && func) {
    return "0.01";
  }
  return val.toFixed(2);
}
</script>
