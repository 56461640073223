<template>
  <div v-loading="isLoading" style="height: 87vh">
    <div style="display: flex; margin-top: 20px; margin-bottom: 35px">
      <el-radio-group
        style="margin-right: 10px"
        size="small"
        v-model="notifyFilter.notifyStatus"
        @change="getNotifyList"
      >
        <el-radio-button value="all">全部</el-radio-button>
        <el-radio-button value="unread">未读</el-radio-button>
      </el-radio-group>
      <el-link type="primary" @click="setNotifyReaded('all')">全部已读</el-link>
    </div>
    <el-empty v-if="notifyList.length == 0" description="您没有通知消息哦！" />
    <div v-else>
      <div v-for="notifyItem in notifyList" :key="notifyItem">
        <div style="margin-bottom: 30px">
          <div
            :style="`
          padding: 0px 10px;
          margin-bottom: -10px;
          margin-top: -20px;
          background-color: #ebedf0;
          border-left: 5px solid ${
            notifyItem.status == 1 ? 'grey' : 'var(--el-color-primary)'
          };
        `"
            class="tip custom-block"
          >
            <h6 style="padding: 8px 2px">
              <div style="font-size: 13px; font-weight: 300">
                {{ notifyItem.message }}
              </div>
              <div style="font-size: 12px; color: grey; margin-top: 10px">
                {{ notifyItem.sended_at }}
              </div>
              <div style="text-align: right">
                <el-link
                  v-if="notifyItem.status == 0"
                  type="primary"
                  @click="setNotifyReaded(notifyItem.id)"
                  >已读消息</el-link
                >
                <el-link
                  v-if="notifyItem.status == 1"
                  @click="deleteNotify(notifyItem.id)"
                  type="danger"
                  >删除消息</el-link
                >
              </div>
            </h6>
          </div>
        </div>
      </div>
      <el-pagination
        style="margin-right: 10px; margin-top: 10px"
        small
        background
        v-model:current-page="notifyFilter.pageNumber"
        v-model:page-size="notifyFilter.pageSize"
        @current-change="getNotifyList"
        @size-change="getNotifyList"
        layout="prev, pager, next"
        :pager-count="5"
        :total="allNotifyTotal"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue";
import { requestApi, showNotification } from "../../plugins/common";

let notifyList = ref([]);
let allNotifyTotal = ref(99);
let unReadNotifyTotal = ref(0);
let notifyFilter = reactive({
  pageNumber: 1,
  pageSize: 5,
  notifyStatus: "all",
});
let isLoading = ref(false);

const getNotifyList = () => {
  isLoading.value = true;
  requestApi(
    "/user/notify/list",
    (res) => {
      isLoading.value = false;
      if (res.code == 1) {
        allNotifyTotal.value = res.data.total;
        unReadNotifyTotal.value = res.data.unread_total;
        notifyList.value = res.data.list;
      }
    },
    {
      page: notifyFilter.pageNumber,
      size: notifyFilter.pageSize,
      status: notifyFilter.notifyStatus,
    },
    {},
    "get",
    false,
    false,
    false
  );
};
getNotifyList();

const setNotifyReaded = (notifyId) => {
  requestApi(
    "/user/notify/set_readed",
    (res) => {
      showNotification(res);
      getNotifyList();
    },
    {},
    { notify_id: notifyId },
    "post"
  );
};

const deleteNotify = (notifyId) => {
  requestApi(
    "/user/notify/delete",
    (res) => {
      showNotification(res);
      getNotifyList();
    },
    {},
    { notify_id: notifyId },
    "post"
  );
};
</script>
