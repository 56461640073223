<template>
  <router-view />
</template>
<style>
html,
body,
#app {
  overflow-x: hidden;
  height: 100%;
  margin: 0px;
  padding: 0px;
  --el-font-size-base: 13px;
  font-family: Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial,
    PingFangSC-Light, "Hiragina Sans GB", "WenQuanYi Micro Hei", "microsoft yahei ui",
    "microsoft yahei", sans-serif;
}
</style>
