import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"0px","margin":"0px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { shadow: "hover" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_page_header, {
        onBack: $setup.goBack,
        title: $setup.currentRoute.meta.title ? $setup.currentRoute.meta.title : '页面'
      }, {
        content: _withCtx(() => [
          _createElementVNode("h6", _hoisted_1, " 首页 > " + _toDisplayString($setup.currentRoute.meta.title ? $setup.currentRoute.meta.title : "页面") + " " + _toDisplayString($setup.currentRoute.query.domain == undefined
              ? ""
              : ` > ${$setup.currentRoute.query.domain}`), 1)
        ]),
        _: 1
      }, 8, ["onBack", "title"])
    ]),
    _: 1
  }))
}