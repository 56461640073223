<template>
  <el-card shadow="hover">
    <el-page-header
      @back="goBack"
      :title="currentRoute.meta.title ? currentRoute.meta.title : '页面'"
    >
      <template #content>
        <h6 style="padding: 0px; margin: 0px">
          首页 >
          {{ currentRoute.meta.title ? currentRoute.meta.title : "页面" }}
          {{
            currentRoute.query.domain == undefined
              ? ""
              : ` > ${currentRoute.query.domain}`
          }}
        </h6>
      </template>
    </el-page-header>
  </el-card>
</template>
<script lang="ts">
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    let currentRoute = router.currentRoute;

    function goBack() {
      router.back();
    }

    return {
      currentRoute,
      goBack,
    };
  },
};
</script>
